import React from "react";
import Layout from "../components/layout";
import { PokeMegRater } from "../components/svg/pokeMegIcons";
import SEO from "../components/seo"

const ThanksPage = () => (
    <Layout>
        <SEO title="thank-you" />
        <div className="flex flex-col justify-center items-center">
            <PokeMegRater className="h-40 my-6"></PokeMegRater>
            <div className="font-semibold text-5xl text-orange">
                Thank you!
            </div>
            <div className="text-lg text-black my-2">
                You'll hear back in a splash.
            </div>
        </div>
    </Layout>
) 

export default ThanksPage